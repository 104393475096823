#modal-holder {
  background: #000a;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 4;
  overflow: hidden;
  padding: 50px 0px;
  width: 100%;
  height: 100%;
}
#modal-holder #filters-modal {
  overflow-y: scroll;
}
#modal-holder #filters-modal .checkbox {
  margin: 10px 0;
}
#modal-holder #filters-modal .checkbox input {
  display: none;
}
#modal-holder #filters-modal .checkbox label {
  color: #000;
}
#modal-holder #filters-modal .championship-filters-container {
  padding: 10px 0px;
}
#modal-holder .close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 50px;
  border: 1px solid #fff;
  color: #fff;
  margin-top: 20px;
}
#modal-holder .close-button img {
  width: 20px;
}
#modal-holder .modal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
}
#modal-holder .modal .graphic {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../images/texture.png");
  background-repeat: repeat;
  overflow: hidden;
  flex-shrink: 0;
  padding: 10px;
}
#modal-holder .modal .graphic img {
  height: 220px;
}
#modal-holder .modal .content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
#modal-holder .modal .content > *:not(:last-child) {
  margin-bottom: 10px;
}
#modal-holder .modal .content .big-words {
  font-weight: bold;
  margin-bottom: 10px;
  color: #616161;
}
#modal-holder .modal .content .small-words {
  line-height: 1.4;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 15px;
}
#modal-holder .modal .content .small-words .user-card {
  width: 100%;
  border-radius: 5px;
}
#modal-holder .modal .content .buttons {
  display: flex;
  margin: 0 -5px;
  justify-content: stretch;
}
#modal-holder .modal .content .buttons > * {
  flex-grow: 1;
  margin: 0 5px;
}
#modal-holder .modal .content .buttons .cancel {
  background: #aaaaaa;
}
#modal-holder.inactive {
  display: none;
}
@media all and (min-width: 600px) {
  #modal-holder .modal {
    flex-direction: row;
  }
  #modal-holder .modal .graphic {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
@media all and (min-width: 1000px) {
  #modal-holder .modal .graphic {
    flex-shrink: 0;
  }
  #modal-holder .modal .graphic img {
    width: unset;
  }
  #modal-holder .modal .big-words {
    font-family: Muli;
    font-size: 26px;
  }
  #modal-holder .modal .small-words {
    line-height: 2;
  }
}
