#event-list {
  border-top: 1px solid #aaaaaa;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  overflow: auto;
}
#event-list.empty {
  background: url("../../images/texture.png");
}
#event-list .cards-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}
#event-list .cards-holder .cards {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  justify-content: space-between;
}
#event-list .cards-holder .cards .card-holder:hover,
#event-list .cards-holder .cards .card-holder:focus-within {
  animation: 0.25s CARD_BOUNCE;
}
#event-list .cards-holder .cards .event-card {
  padding: 20px;
  outline: none;
  border-radius: 10px;
  margin: 10px 15px;
}
#event-list .cards-holder .cards .event-card:focus-visible {
  border: 3px solid #e23418;
}
#event-list .cards-holder .cards .tagged-event-card {
  padding-right: 30px;
  border-top-right-radius: 0;
}
#event-list > div:first-child {
  flex-grow: 1;
}
@keyframes CARD_BOUNCE {
  50% {
    padding-bottom: 10px;
    margin-top: -10px;
  }
}
@media all and (min-width: 1000px) {
  #event-list {
    border-top: none;
  }
  #event-list .cards-holder .cards {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  #event-list .cards-holder .cards .event-card {
    border-bottom-width: 0;
  }
}
