.header-bar {
  justify-content: flex-start;
  display: flex;
  min-height: 75px;
  background: #333;
  align-items: center;
  padding: 0px 10px;
  z-index: 3;
  flex-shrink: 0;
}
.header-bar .menu {
  display: flex;
}
.header-bar .retail {
  align-items: flex-end !important;
  padding-bottom: 10px;
}
.header-bar .retail .search-holder {
  margin-right: 15px;
}
.header-bar .retail .distance-label {
  color: #fff;
}
.header-bar .retail .dropdown-menu-bar {
  color: #fff;
}
.header-bar .search-and-filter {
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 10px;
}
.header-bar .search-and-filter > * {
  padding-bottom: 10px;
}
.header-bar .distance-control,
.header-bar .filters-and-controls {
  display: flex;
}
.header-bar .filters {
  flex-wrap: nowrap;
}
.header-bar .no-label {
  padding-top: 22px;
}
.header-bar .filter {
  margin-right: 10px;
}
.header-bar .search-holder {
  flex: 1;
  max-width: 100%;
}
.header-bar .hidden-sm {
  display: flex;
}
@media (width < 1000px) {
  .header-bar .hidden-sm {
    display: none;
  }
}
.header-bar .filters-and-bounds {
  display: flex;
  flex-shrink: 1;
}
.header-bar .filters-and-bounds > * {
  margin-left: 0;
  margin-right: 0;
}
.header-bar .filters-and-bounds > *:not(:last-child):not(.spacer) {
  margin-right: 10px;
}
.header-bar .input-label {
  color: #fff;
}
.header-bar .group-label {
  font-size: 12px;
  margin-top: 6px;
}
.header-bar .distance-control .input-holder {
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
}
.header-bar .date-control input,
.header-bar .distance-control .input-holder {
  flex-grow: 1;
}
.header-bar .login {
  display: flex;
  padding-left: 10px;
  align-items: center;
  align-items: flex-end;
}
.header-bar .login .spinner img {
  height: 40px;
  width: 40px;
}
.header-bar #edb-login span[class*="login_iconText"] {
  display: none;
}
.header-bar #edb-login svg.fa-user-circle {
  font-size: 40px;
}
.header-bar #edb-login div[class*="login_loggedInDropdown"] {
  right: -15px;
}
.header-bar .events .toggle-row {
  width: 700px;
}
.header-bar .toggle-row {
  display: flex;
  max-width: 100%;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
}
.header-bar .toggle-row .search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
}
.header-bar .toggle-row .search-exit {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 2px;
  font-size: 30px;
  color: #fff;
  font-family: "Helvetica Neue";
}
.header-bar .toggle-row .toggle {
  font-size: 14px;
  font-family: "Flexo-Heavy";
  display: flex;
  padding: 0 7px;
  flex: 1;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #aaaaaa;
  color: #fff;
  height: 35px;
  margin-top: 20px;
  margin: 2px;
}
.header-bar .toggle-row .toggle.active {
  background: #ed6935;
}
.header-bar .toggle-row .toggle:last-child {
  margin-left: 5px;
}
@media all and (max-width: 1000px) {
  .header-bar .toggle-row .toggle {
    margin-top: 0px;
  }
}
@media all and (min-width: 1000px) {
  .header-bar .search-holder {
    align-items: center;
    flex-basis: 350px;
    min-width: 350px;
    max-width: 100%;
  }
  .header-bar .search-and-filter .left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .header-bar .search-and-filter .left .filters-and-controls {
    margin-left: 15px;
    display: flex;
  }
  .header-bar #edb-login span[class*="login_iconText"] {
    display: block;
  }
}
@media all and (max-width: 450px) {
  .search-holder {
    max-width: 290px;
  }
  .retail .search-holder {
    min-width: 100%;
  }
}
