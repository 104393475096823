.search-holder {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 18px;
  color: #000;
  border-radius: 5px;
}
.search-holder input {
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  overflow: hidden;
  border-width: 0;
  text-overflow: ellipsis;
  margin-right: 15px;
  font-family: "Flexo-Demi";
  flex: 1;
  color: #000;
}
.search-holder input,
.search-holder select {
  font-size: 100%;
}
.search-holder .autocomplete-dropdown-container {
  box-sizing: content-box;
  background: #ffffff;
  font-size: 0.8em;
  position: absolute;
  margin-left: -1px;
  overflow: hidden;
  border-radius: 5px;
  z-index: 4;
  margin-top: 10px;
  top: 100%;
  width: 100%;
  max-width: 100%;
}
.search-holder .autocomplete-dropdown-container .suggestion-item {
  width: 100%;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.75em;
}
.search-holder .autocomplete-dropdown-container .suggestion-item:first-child {
  border-top: 1px solid #e0e0e0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.search-holder .autocomplete-dropdown-container .suggestion-item:last-child {
  border-bottom: 1px solid #e0e0e0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.search-holder .autocomplete-dropdown-container .suggestion-item:hover {
  color: #fff;
  background: #ed6935;
}
.search-holder .location-icon {
  margin-right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #ed6935;
  cursor: pointer;
  overflow: hidden;
}
.search-holder .location-icon img {
  max-width: calc(100% - 5px);
}
@media all and (min-width: 1000px) {
  .search-holder input {
    font-size: 14px;
    padding: 13px 15px 13px 9px;
  }
}
