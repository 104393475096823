.details-box {
  border: 1px solid #ccc;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
}
.details-box:hover,
.details-box:focus {
  background-color: #ddd;
}
.details-box .section-header-holder {
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-bottom: -2px;
  font-family: Flexo-Medium;
  font-size: 16px;
}
.details-box .section-header-holder .collapser {
  width: 1em;
  font-size: 1.5em;
  transform: rotate(90deg) translateX(4px);
  transition-duration: 0.25s;
}
.details-box.collapsed {
  max-height: 55px;
  overflow: hidden;
}
.details-box.collapsed .collapser {
  transform: rotate(0) translateX(0) translateY(-2px);
}
.details-box.collapsed .section-header-holder {
  margin-bottom: 15px;
}
.details-box .deets {
  padding-left: 10px;
  color: #aaaaaa;
  margin-left: 10px;
  border-left: 1px solid #ccc;
}
