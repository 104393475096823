.event-type-dropdown-container {
  min-width: 175px;
  flex-grow: 1;
}
.event-types-container {
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.event-types-container.disabled {
  opacity: 0.5;
}
.event-types-container .event-type-button {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  font-size: 10px;
  font-family: "Flexo-Demi";
  justify-content: flex-start;
  height: 40px;
  min-width: 272px;
  margin-bottom: 5px;
  padding: 0 15px 0 10px;
  flex-grow: 1;
}
.event-types-container .event-type-button .icon {
  height: 25px;
  width: 25px;
}
.event-types-container .event-type-button.active {
  border: none;
  background: #fff;
  color: #000;
}
.event-types-container .event-type-button.active.tournament {
  background: #33a9d6;
  color: #fff;
}
.event-types-container .event-type-button.active.league {
  background: #e23418;
  color: #fff;
}
.event-types-container .event-type-button .label {
  flex: 1;
  text-align: center;
  font-family: "Flexo-Demi";
  font-size: 12px;
}
.event-filter-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 4px;
  width: 90px;
  min-height: 120px;
  padding: 10px;
}
.event-filter-button.active {
  border: 2px solid #33a9d6;
  background: #fff;
  color: #000;
}
.event-filter-button.active .label {
  color: #33a9d6;
}
.event-filter-button:first-child {
  margin-left: 0;
}
.event-filter-button:last-child {
  margin-right: 0;
}
.event-filter-button .sub-type-icon {
  max-height: 50px;
}
@media all and (max-width: 1000px) {
  .event-filter-button .sub-type-icon {
    max-height: 50px;
  }
}
.event-filter-button .label {
  overflow-wrap: break-word;
  text-align: center;
  font-size: 11px;
  font-family: "Flexo-Demi";
  color: #000;
}
.event-sub-types-container {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  padding-bottom: 20px;
}
.event-sub-types-container .pes-label {
  font-size: 14px;
  font-family: "Flexo-Demi";
  margin-bottom: 4px;
}
.event-sub-types-container .event-sub-type-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.event-sub-types-container .event-sub-type-row.disabled {
  opacity: 0.25;
}
.event-sub-types-container .event-sub-type-row:last-child {
  margin-bottom: 0px;
}
.event-sub-types-container .event-sub-type-row .event-sub-type-label {
  font-family: "Flexo-Demi";
  font-size: 12px;
  color: #333;
}
.event-sub-types-container .event-sub-type-row .event-sub-type-group {
  display: flex;
  flex-direction: row;
}
.event-type-filters-container {
  margin-top: 20px;
}
@media all and (max-height: 750px) {
  .event-type-filters-container.scroll-height {
    height: 400px;
    overflow-y: scroll;
  }
}
.event-type-filters-container .event-type-filters-label {
  font-size: 12px;
  color: #000;
  font-family: "Flexo-Demi";
}
.event-type-filters-container .reset-filters-container {
  text-align: right;
  padding-bottom: 5px;
}
.event-type-filters-container .reset-filters-container .reset-filters-button {
  color: #33a9d6;
  font-size: 12px;
  font-family: "Flexo-Demi";
}
.close-button {
  text-align: center;
  font-size: 12px;
  font-family: "Flexo-Demi";
  color: #777;
}
