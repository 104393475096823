.optin-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.optin-content .optin-header {
  font-size: 25px;
  font-family: "Flexo-demi";
  margin-bottom: 20px;
}
.optin-content .optin-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
}
.optin-content .optin-row .step {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  padding: 20px;
  height: 50px;
  width: 50px;
  background-color: #93d698;
  margin-right: 10px;
  font-family: "Flexo-demi";
  font-size: 25px;
}
.optin-content .optin-row .directions {
  display: flex;
  flex-direction: column;
}
.optin-content .optin-row .directions .header {
  font-family: "Flexo-demi";
  font-size: 18px;
  margin-bottom: 5px;
}
.optin-content .optin-row .directions .description {
  font-size: 14px;
}
.optin-content .optin-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  font-family: "Flexo-demi";
  color: #000;
}
.optin-content .optin-button.confirm {
  background-color: #23ce40;
  color: #fff;
}
.optin-content .optin-button.confirm:hover {
  background-color: #93d698;
}
.optin-content .optin-button:last-child {
  margin-top: 5px;
}
