#registry-form .input-set {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5em;
}
#registry-form .input-set > * {
  flex-grow: 1;
}
#registry-form .input-set > * {
  margin: 0 0.5em;
}
#registry-form .input-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
#registry-form textarea {
  border: 1px solid #000;
  font-size: 24px;
  padding: 0.25em 0.5em;
  height: 200px;
  min-width: 100%;
  max-width: 100%;
}
#registry-form input[type="text"] {
  border: 1px solid #000;
  font-size: 24px;
  padding: 0.25em 0.5em;
}
#registry-form input[type="submit"] {
  border: 1px solid #000;
  font-size: 24px;
  background: #33a9d6;
  align-self: flex-end;
  cursor: pointer;
  padding: 0.5em 1em;
  transition-duration: 0.25s;
}
#registry-form input[type="submit"]:hover {
  border-color: #fff;
  background: #05afc3;
  color: #fff;
}
input:focus-visible {
  color: #333;
  background-color: #eee;
}
#team-challenge-banner {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
}
#team-challenge-banner .bg-holder {
  background-color: #333;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#team-challenge-banner .bg-holder .left-bg {
  background-color: #efefef;
  transform: skewX(-15deg);
  margin-left: -15px;
  width: 135px;
  height: 100%;
}
#team-challenge-banner .fg-holder {
  z-index: 2;
  background-image: url("../../images/texture.png");
  width: 100%;
  display: flex;
}
#team-challenge-banner .headline {
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-weight: bold;
  font-size: 20px;
  text-shadow: 0 0 1px #000;
}
#team-challenge-banner .pikachu-holder {
  margin-right: 5px;
}
#team-challenge-banner .pikachu-holder img {
  max-width: 125px;
  filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
}
#team-challenge-banner .badge-holder img {
  max-width: 125px;
}
