#event-finder {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: stretch;
}
#event-finder .content-holder {
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column-reverse;
  overflow: hidden;
  flex-grow: 1;
}
#event-finder .event-list-slider.mapless {
  flex-grow: 1;
  height: calc(100% - 1px);
}
#event-finder .event-list-slider:not(.mapless) {
  height: calc(100% - 200px);
}
#event-finder .event-list {
  padding: 25px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media all and (min-width: 1000px) {
  #event-finder .content-holder {
    flex-direction: row;
    justify-content: space-between;
  }
  #event-finder .content-holder .event-list-slider {
    width: 600px;
  }
  #event-finder .content-holder .event-list-slider.mapless,
  #event-finder .content-holder .event-list-slider:not(.mapless) {
    height: initial;
  }
  #event-finder .content-holder .event-list-slider .event-list {
    padding: initial;
  }
}
@media only screen and (orientation: landscape) {
  .event-list-slider {
    height: 50vh;
  }
}
