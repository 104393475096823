.card-holder .event-card {
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: hidden;
  padding: 3px;
  background-color: #e23418;
  color: #fff;
  cursor: default;
  user-select: none;
}
.card-holder .event-card.non-event {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.card-holder .event-card.non-event .address-block {
  margin-top: 15px;
  flex-direction: column;
}
.card-holder .event-card.mini {
  padding: 20px 30px 20px 20px;
  border-radius: 10px;
  box-shadow: none;
}
.card-holder .event-card.mini.tagged-event {
  border-top-right-radius: 0;
}
.card-holder .event-card.mini .event-header {
  -webkit-line-clamp: initial;
}
.card-holder .event-card:not(.mini) {
  cursor: pointer;
}
.card-holder .event-card > *:not(:last-child) {
  margin-bottom: 5px;
}
.card-holder .event-card.tournament,
.card-holder .event-card.retail {
  background: #33a9d6;
}
.card-holder .event-card .date-and-distance {
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: space-between;
  font-size: 14px;
  margin: 0px 10px 10px 0px;
}
.card-holder .event-card .date-and-distance .when {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}
.card-holder .event-card .bottom-row {
  display: flex;
  justify-content: space-between;
}
.card-holder .event-card .date-and-time {
  max-width: 50px;
  flex-shrink: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}
.card-holder .event-card .date-and-time .day-and-month {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-holder .event-card .date-and-time .date {
  font-size: 20px;
}
.card-holder .event-card .date-and-time .time {
  font-size: 14px;
}
.card-holder .event-card .tags {
  display: flex;
  flex-direction: row;
}
.card-holder .event-card .center-column {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.card-holder .event-card .right-column {
  flex-shrink: 0;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-holder .event-card .event-header {
  line-height: 1.1;
  white-space: initial;
  font-family: "Flexo-Heavy";
  font-size: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-holder .event-card .address-block {
  font-size: 0.9em;
  display: grid;
  flex-wrap: wrap;
}
.card-holder .event-card .address-block > *:not(:last-child) {
  margin-right: 0.5em;
}
.card-holder .event-card .event-pillbox {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: fit-content;
  min-width: 6rem;
  margin: 0px 0px 5px 0px;
  color: #33a9d6;
  font-weight: bold;
  border-radius: 25px;
  background: #fff;
  padding: 3px 10px;
}
.card-holder .event-card .name-tag-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0px 3px 0px;
}
.card-holder .event-card .address {
  white-space: normal;
  inline-size: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card-holder-grid {
  display: grid !important;
  grid-template-columns: auto 5.5rem;
}
/* Workaround to add shadow to the cards */
.add-shadow {
  box-shadow: 0px 2px 5px #ccc;
}
.tagged-event {
  margin-right: 0;
  clip-path: polygon(0 0, 100% 0%, 93% 100%, 0% 100%);
  z-index: 2;
  grid-column-start: 1;
  grid-row-start: 1;
}
.tag-holder {
  margin: 0 2px;
}
.logo-holder {
  margin: 10px 15px 10px 15px;
  background-color: #ededed;
  border-radius: 10px;
  padding: 20px 12px 20px 20px;
  grid-column: span 2 / -1;
  grid-row: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.logo-holder.newer-logo {
  padding: 20px 7.5px 20px 20px;
}
.logo-holder img {
  max-height: 75px;
}
.mobile-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.mobile-event-info {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  width: 100%;
}
#league-detail-view .logo-holder {
  margin: 0;
}
@media all and (max-width: 1000px) {
  .card-holder-grid {
    grid-template-columns: auto 6.5rem;
  }
  .logo-holder {
    margin: 0px 15px 15px 15px;
    padding: 20px;
  }
  .logo-holder.newer-logo {
    padding: 20px;
  }
  .logo-holder img {
    max-height: 80px;
  }
  #event-list .cards-holder .cards .event-card {
    border-radius: 10px 0px 0px 10px;
    border-bottom-width: 0;
    padding: 15px;
    margin: 0 15px 15px 15px;
  }
}
@media all and (max-width: 720px) {
  .tag-holder {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .main-info {
    grid-template-columns: none;
    grid-template-rows: auto auto;
  }
  .card-holder-grid {
    grid-template-columns: auto 3rem;
  }
  .logo-holder img {
    height: 50px;
  }
  .logo-holder {
    padding: 20px 2px 20px 20px;
  }
  .logo-holder.newer-logo {
    padding: 20px 2px 20px 20px;
  }
  .card-holder .event-card .date-and-distance {
    align-items: inherit;
    margin: 0;
  }
  .card-holder .event-card .when {
    display: flex;
    flex-flow: wrap;
  }
  .card-holder .event-card .when .distance {
    margin-left: 10px;
  }
  .card-holder .event-card .event-pillbox {
    margin: 3px 0px 2px 0px;
  }
  .card-holder .event-card .event-name {
    margin-bottom: 0px;
  }
  .card-holder .event-card.tagged-event .date-and-distance {
    display: grid;
    grid-template-columns: repeat(2, min-content);
  }
  .card-holder .event-card .tags {
    margin-top: 5px;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: repeat(3, 30px);
  }
  .card-holder .event-card :not(.tagged-event) .name-tag-holder {
    margin: 5px 0px 3px 0px;
  }
}
@media all and (max-width: 350px) {
  .card-holder .event-card.tagged-event .date-and-distance {
    display: grid;
    grid-template-rows: repeat(2, 50%);
    grid-template-columns: none;
  }
}
@media all and (max-width: 325px) {
  .card-holder .event-card .name-tag-holder {
    margin-top: 5px;
  }
}
@media all and (min-width: 500px) {
  .event-card .event-header {
    font-size: 22px;
  }
  .event-card .date-and-time {
    width: 65px;
  }
  .event-card .date-and-time .date {
    font-size: 30px;
  }
  .event-card .date-and-time .time {
    font-size: 14px;
  }
}
