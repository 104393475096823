.spinner {
  display: flex;
  font-size: 30px;
  color: white;
}
.spinner .left-spacer,
.spinner .right-spacer {
  flex-grow: 1;
}
.spinner img {
  animation: 2s SPINNY infinite linear;
  max-height: 100px;
  max-width: 100px;
  width: 100px;
  height: 100px;
}
@keyframes SPINNY {
  100% {
    transform: rotate(360deg);
  }
}
