#confirm-modal {
  width: 30%;
}
@media all and (max-width: 1500px) {
  #confirm-modal {
    width: 40%;
  }
}
@media all and (max-width: 700px) {
  #confirm-modal {
    width: 80%;
  }
}
@media all and (max-width: 1000px) {
  #confirm-modal {
    width: 80%;
  }
}
.confirm-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.confirm-content .link {
  color: #55d9f2;
  font-family: "Flexo-demi";
}
.confirm-content .confirm-header {
  font-size: 25px;
  font-family: "Flexo-demi";
  margin-bottom: 20px;
}
.confirm-content .confirm-text {
  font-size: 13px;
}
.confirm-content .confirm-part {
  margin-bottom: 10px;
}
.confirm-content .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.confirm-content .confirm-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  font-family: "Flexo-Demi";
  color: #000;
}
.confirm-content .confirm-button.confirm {
  background-color: #23ce40;
  color: #fff;
}
.confirm-content .confirm-button.confirm:hover {
  background-color: #93d698;
}
.confirm-content .cancel-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  font-family: "Flexo-Demi";
  color: #000;
}
.confirm-content .footer {
  margin-top: 20px;
}
