.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
}
.checkbox label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-family: "Flexo-demi";
  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 30px;
}
.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: "";
  /*Needed for the line-height to take effect*/
  display: inline-block;
}
/*Outer box of the fake checkbox*/
.checkbox label::before {
  height: 16px;
  width: 16px;
  border: 1px solid #777;
  border-radius: 5px;
  left: 0px;
  padding: 1px;
  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
}
/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 16px;
  width: 16px;
  background-color: #33a9d6;
  border-radius: 4px;
  left: 2px;
}
/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
  content: none;
}
/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}
/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="checkbox"]:focus + label::before {
  outline: #3b99fc auto 5px;
}
/***************END**************/
