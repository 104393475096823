.distance-dropdown-container {
  display: flex;
  min-width: 100px;
  flex-direction: column;
  gap: 0.25rem;
}
.distance-dropdown-container .distance-label {
  font-family: "Flexo-Demi";
  font-size: 12px;
}
.distance-dropdown-container .distance-dropdown-button {
  display: flex;
  align-items: center;
  width: 55px;
  min-height: 30px;
  font-size: 12px;
  font-family: "Flexo-Demi";
  color: #000;
}
.distance-dropdown-container .distance-dropdown-button.active {
  color: #33a9d6;
}
.distance-filter-container {
  padding: 10px;
  min-width: 100px;
}
