.pokemon-gus-container.hidden * {
  display: none;
}
.pokemon-gus-container a {
  max-height: 54px;
}
#info-notifier {
  border-radius: 10px;
  margin: 50px 50px 0;
  padding: 30px 20px;
  overflow: hidden;
  box-shadow: 0px 2px 5px #ccc;
  display: flex;
  flex-shrink: 0;
  background: #fff;
  align-items: flex-start;
}
#info-notifier .icon {
  height: 50px;
  width: 50px;
  margin-right: 20px;
}
#info-notifier img {
  user-select: none;
  height: 100px;
}
#info-notifier .title {
  font-weight: 500;
  font-size: 30px;
  display: flex;
  color: #000;
  font-family: "Flexo-Heavy";
  align-items: flex-start;
}
#info-notifier .body {
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
#info-notifier .body .content {
  margin-top: 5px;
  font-size: 14px;
  align-self: center;
}
#info-notifier .body .art-row {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#info-notifier .body .art-row .art-holder {
  background: url("../../images/icons/spinner-ball.svg");
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;
}
#info-notifier .body .art-row .art-holder img {
  width: 100%;
  height: 100%;
}
@media all and (max-width: 1000px) {
  #info-notifier {
    margin: 30px;
  }
  #info-notifier .title {
    font-size: 25px;
  }
  #info-notifier .body .content {
    line-height: 16px;
  }
  #info-notifier .body .art-row {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
