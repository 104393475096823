.message-box-container {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.message-box-container .message-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 775px;
  border-radius: 15px;
  font-size: 14px;
  flex-wrap: wrap;
  padding: 25px 20px;
}
.message-box-container .message-box.error {
  border: 1px solid #e23418;
}
.message-box-container .message-box.error .message {
  font-family: "Flexo-demi";
  color: #e23418;
}
.message-box-container .message-box.success {
  background: #000;
  color: #fff;
}
.message-box-container .message-box.success .bold-label {
  font-family: "Flexo-demi";
  margin-right: 5px;
}
.message-box-container .message-box.success .message-box-button {
  display: flex;
  flex-grow: 1;
}
.message-box-container .message-box.success .message-box-button .pgo-button {
  flex-grow: 1;
}
.message-box-container .message-box.success .message {
  width: 65%;
  text-align: center;
}
@media all and (max-width: 1000px) {
  .message-box-container .message-box.success .message {
    width: 100%;
    margin-bottom: 15px;
  }
}
.message-box-enter {
  opacity: 0;
  transform: scale(0.9);
}
.message-box-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.message-box-exit {
  opacity: 1;
}
.message-box-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
