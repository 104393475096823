#sort-control {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}
#sort-control .count {
  font-size: 14px;
}
#sort-control .sort-type-container {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
#sort-control .sort-type {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}
#sort-control .sort-type:hover span {
  color: #05afc3;
  cursor: pointer;
}
