.product-filters-container {
  display: flex;
  flex-direction: column;
}
.product-filters-label {
  font-size: 12px;
  font-family: "Flexo-Demi";
}
.product-filter-items {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  justify-content: space-between;
}
.product-dropdown-button {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-family: "Flexo-Demi";
  justify-content: center;
  width: 90px;
  height: 120px;
  padding: 10px;
}
.product-dropdown-button .icon {
  height: 50px;
}
.product-dropdown-button.active {
  border: 2px solid #33a9d6;
  background: #fff;
}
.product-dropdown-button.active .label {
  color: #33a9d6;
}
.product-dropdown-button .label {
  word-wrap: break-word;
  text-align: center;
  font-family: "Flexo-Demi";
  font-size: 11px;
  color: #000;
  margin-top: 5px;
  hyphens: auto;
}
