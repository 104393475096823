#event-fetch-placeholder {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin: 25px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#event-fetch-placeholder .spinner {
  width: 100%;
  margin-bottom: 30px;
}
#event-fetch-placeholder .info {
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#event-fetch-placeholder .info .header {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}
#event-fetch-placeholder .info .specs {
  text-align: center;
  font-size: 14px;
}
#event-fetch-placeholder .info .specs .search-term {
  text-decoration: underline;
  font-weight: bold;
}
@media all and (min-width: 1000px) {
  #event-fetch-placeholder {
    width: unset;
    height: unset;
    justify-content: center;
  }
  #event-fetch-placeholder .info {
    margin-top: 0px;
    margin-left: 25px;
  }
}
@media all and (min-width: 500px) {
  #event-fetch-placeholder .info .header {
    font-size: 28px;
  }
  #event-fetch-placeholder .info .specs {
    font-size: 16px;
  }
}
