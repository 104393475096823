#map-container {
  align-self: stretch;
  justify-self: stretch;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  transition-duration: 0.5s;
  flex-grow: 1;
  background: #05afc3;
  display: flex;
  overflow: hidden;
  background: #33a9d6;
}
#map-container.hidden {
  max-height: 0;
  min-height: 0;
  border-width: 0;
}
#map-container .map-holder {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
@media all and (min-width: 1000px) {
  #map-container {
    max-height: 100%;
    height: 100%;
    min-height: 100%;
    border-bottom-width: 0;
    flex-basis: 450px;
    background-image: linear-gradient(135deg, #05afc3 50%, #048291);
  }
}
@media only screen and (orientation: landscape) {
  #map-container {
    min-height: 100px;
  }
}
