#registry-form .input-set {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5em;
}
#registry-form .input-set > * {
  flex-grow: 1;
}
#registry-form .input-set > * {
  margin: 0 0.5em;
}
#registry-form .input-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
#registry-form textarea {
  border: 1px solid #000;
  font-size: 24px;
  padding: 0.25em 0.5em;
  height: 200px;
  min-width: 100%;
  max-width: 100%;
}
#registry-form input[type="text"] {
  border: 1px solid #000;
  font-size: 24px;
  padding: 0.25em 0.5em;
}
#registry-form input[type="submit"] {
  border: 1px solid #000;
  font-size: 24px;
  background: #33a9d6;
  align-self: flex-end;
  cursor: pointer;
  padding: 0.5em 1em;
  transition-duration: 0.25s;
}
#registry-form input[type="submit"]:hover {
  border-color: #fff;
  background: #05afc3;
  color: #fff;
}
input:focus-visible {
  color: #333;
  background-color: #eee;
}
.account-button {
  width: 55px;
  height: 55px;
  display: flex;
  align-self: center;
  border: 2px solid #fff;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  overflow: hidden;
  border-radius: 50px;
  flex-shrink: 0;
  transition-duration: 0.25s;
}
.account-button.logged-in {
  background: #23ce40;
}
.account-button:hover {
  background: #147725;
  animation: WIGGLE 0.5s;
}
.account-button img {
  max-width: 110%;
  max-height: 110%;
}
@keyframes WIGGLE {
  25% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-5deg);
  }
}
