.championship-filters-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.championship-filters-container .reset-filters {
  font-size: 12px;
  font-family: "Flexo-Demi";
  text-align: right;
  color: #33a9d6;
  cursor: pointer;
}
.championship-filters-container .championship-filters-label {
  font-size: 12px;
  font-family: "Flexo-Demi";
}
.championship-filters-container .championship-group {
  display: flex;
  flex-direction: row;
}
.championship-filters-container .championship-filter {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 100px;
  width: 85px;
  max-width: 85px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 4px;
  padding: 10px;
}
.championship-filters-container .championship-filter.active {
  border: 2px solid #33a9d6;
}
.championship-filters-container .championship-filter.active .label {
  color: #33a9d6;
}
.championship-filters-container .championship-filter:first-child {
  margin-left: 0;
}
.championship-filters-container .championship-filter:last-child {
  margin-right: 0;
}
.championship-filters-container .championship-filter .sub-type-icon {
  max-height: 50px;
}
@media all and (max-width: 1000px) {
  .championship-filters-container .championship-filter .sub-type-icon {
    max-height: 50px;
  }
}
.championship-filters-container .championship-filter .label {
  hyphens: auto;
  overflow-wrap: break-word;
  text-align: center;
  font-size: 10px;
  font-family: "Flexo-Demi";
  color: #000;
}
