.dropdown-menu-container {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.dropdown-menu-container .dropdown-menu-bar {
  align-items: center;
  color: #000;
  display: flex;
  font-family: "Flexo-Demi";
  font-size: 12px;
  justify-content: space-between;
  flex-grow: 1;
  min-height: 40px;
  padding: 0 10px;
}
.dropdown-menu-container .dropdown-menu-bar .carat-down {
  font-size: 20px;
  padding-bottom: 3px;
  margin-left: 5px;
}
.dropdown-menu-container .dropdown-menu-bar .carat-down.dark {
  color: #fff;
}
.dropdown-menu-container .dropdown-menu-bar .left-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dropdown-menu-container .dropdown-menu-bar .left-content.dark {
  color: #fff;
}
.dropdown-menu-container .dropdown-menu-bar .left-content.light {
  color: #ccc;
}
.dropdown-menu-container .dropdown-menu-bar .left-content .count {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: "Flexo-Demi";
  height: 15px;
  width: 15px;
  padding: 10px;
  margin-left: 10px;
  background-color: #fff;
  border-radius: 5px;
}
.dropdown-menu-container .dropdown-menu {
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #ccc;
  height: auto;
  position: absolute;
  width: auto;
  top: 45px;
  z-index: 3;
}
.dropdown-menu-container .dropdown-menu-button {
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 5px;
  display: flex;
  font-size: 10px;
  font-family: "Flexo-Demi";
  color: #efefef;
  justify-content: flex-start;
  height: 40px;
  margin-bottom: 5px;
  padding: 0 15px 0 0px;
}
.dropdown-menu-container .dropdown-menu-button .icon {
  height: 25px;
}
.dropdown-menu-container .dropdown-menu-button.active {
  border-color: #33a9d6;
  background: #fff;
  color: #000;
}
.dropdown-menu-container .dropdown-menu-button .label {
  flex: 1;
  text-align: center;
  font-family: "Flexo-Demi";
  font-size: 12px;
}
.dropdown-menu-container .dropdown-enter {
  opacity: 0;
  transform: scale(0.9);
}
.dropdown-menu-container .dropdown-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.dropdown-menu-container .dropdown-exit {
  opacity: 1;
}
.dropdown-menu-container .dropdown-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
