.date-control {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Flexo-Demi";
  gap: 0.25rem;
}
.date-control .date-control-label {
  font-size: 12px;
  font-family: "Flexo-Demi";
}

.date-control .react-datepicker-wrapper {
  display: flex !important;
  height: 100%;
}

.date-control .react-datepicker__input-container {
  display: flex !important;
}

.date-control .icon-wrapper {
  display: flex;
  padding: 0.5em;
  cursor: pointer;
}

.date-control label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-control input {
  display: flex;
  max-width: 130px;
  flex-grow: 1;
  font-family: "Flexo-Demi";
  font-size: 12px;
  background: #0000;
  color: #000;
  outline: none;
  border: none;
  text-align: left;
  letter-spacing: 0.5px;
  padding-left: 0.5rem;
}

.date-control label:focus-within {
  color: #333;
  background-color: #eee;
}
.date-control input:focus-visible {
  color: #333;
  background-color: #eee;
  border-radius: 5px;
}
