#modal-holder #leaving-site-modal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
#modal-holder #leaving-site-modal:focus-within {
  color: black;
}
#modal-holder #leaving-site-modal .graphic {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../images/texture.png");
  background-repeat: repeat;
  overflow: hidden;
  flex-shrink: 0;
  padding: 10px;
}
#modal-holder #leaving-site-modal .graphic img {
  height: 150px;
}
#modal-holder #leaving-site-modal .content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-position: left;
}
#modal-holder #leaving-site-modal .content > *:not(:last-child) {
  margin-bottom: 10px;
}
#modal-holder #leaving-site-modal .content .big-words {
  font-weight: bold;
  margin-bottom: 10px;
  color: #616161;
}
#modal-holder #leaving-site-modal .content .small-words {
  font-family: "Muli";
  line-height: 1.4;
  font-size: 15px;
  flex-grow: 1;
}
#modal-holder #leaving-site-modal .content .buttons {
  display: flex;
  margin: 0 -5px;
  justify-content: stretch;
}
#modal-holder #leaving-site-modal .content .buttons > * {
  flex-grow: 1;
  margin: 0 5px;
}
#modal-holder #leaving-site-modal .content .buttons .cancel {
  background: #aaaaaa;
}
@media all and (min-width: 600px) {
  #modal-holder #leaving-site-modal {
    flex-direction: row;
  }
}
@media all and (min-width: 1000px) {
  #modal-holder #leaving-site-modal {
    width: 800px;
  }
  #modal-holder #leaving-site-modal .graphic {
    flex-shrink: 0;
  }
  #modal-holder #leaving-site-modal .graphic img {
    width: unset;
  }
  #modal-holder #leaving-site-modal .big-words {
    font-family: Muli;
    font-size: 26px;
  }
  #modal-holder #leaving-site-modal .small-words {
    line-height: 2;
  }
}
