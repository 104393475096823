.filters-dropdown {
  width: 125px;
}
.filters-container-wrapper {
  position: relative;
}
.filters-container-wrapper .modal-close-button {
  position: absolute;
  bottom: 20px;
}
.filters-reset-button {
  text-align: right;
  color: #33a9d6;
  font-size: 12px;
  font-family: "Flexo-Demi";
}
.filters-container {
  display: flex;
  flex-direction: column;
}
.filters-content {
  padding: 15px 30px;
  width: 360px;
}
.filters-content .flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.filters-content.expanded {
  height: 650px;
  overflow-y: scroll;
}
.filters-content.variable-height {
  height: 65vh;
  overflow: auto;
}
.filters-content.variable-height-more {
  height: 65vh;
}
.footer-button {
  display: flex;
  font-size: 12px;
  font-family: "Flexo-Demi";
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  color: #aaaaaa;
  background: #efefef;
}
