.button {
  background: #05afc3;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #fff;
  user-select: none;
  text-decoration: none;
  transition-duration: 0.25s;
}
.button > *:not(:last-child) {
  margin-right: 0.5em;
}
.button.smaller {
  padding: 0.5em 1em;
}
.button.danger {
  background: #ad0202;
}
.button.danger:hover {
  background: #df0303;
}
.button:hover {
  background: #06dcf5;
}
@media all and (min-width: 500px) {
  .button {
    font-size: 20px;
  }
}
#registry-form .input-set {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5em;
}
#registry-form .input-set > * {
  flex-grow: 1;
}
#registry-form .input-set > * {
  margin: 0 0.5em;
}
#registry-form .input-holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}
#registry-form textarea {
  border: 1px solid #000;
  font-size: 24px;
  padding: 0.25em 0.5em;
  height: 200px;
  min-width: 100%;
  max-width: 100%;
}
#registry-form input[type="text"] {
  border: 1px solid #000;
  font-size: 24px;
  padding: 0.25em 0.5em;
}
#registry-form input[type="submit"] {
  border: 1px solid #000;
  font-size: 24px;
  background: #33a9d6;
  align-self: flex-end;
  cursor: pointer;
  padding: 0.5em 1em;
  transition-duration: 0.25s;
}
#registry-form input[type="submit"]:hover {
  border-color: #fff;
  background: #05afc3;
  color: #fff;
}
input:focus-visible {
  color: #333;
  background-color: #eee;
}
body {
  margin: 0;
}
body * {
  box-sizing: border-box;
}
strong {
  font-weight: 600;
}
.error-message {
  text-align: center;
  font-family: Play;
  color: #ad0202;
  font-size: 20px;
}
.fake-link {
  color: #05afc3;
  cursor: pointer;
}
.fake-link:hover {
  text-decoration: underline;
}
.copy-link {
  display: flex;
  align-items: center;
  color: #aaaaaa;
  cursor: default;
  user-select: none;
}
.copy-link:not(.success) {
  color: #05afc3;
  cursor: pointer;
}
.copy-link:not(.success):hover {
  font-weight: bold;
  text-decoration: underline;
}
.spacer {
  flex-grow: 1;
}
.pokemon-gus-container {
  max-height: 60px;
  z-index: 5;
  transition-duration: 0.25s;
}
.pokemon-gus-container .cookie-notification {
  position: absolute;
}
.pokemon-gus-container .gus-flyout {
  max-width: calc(100% - 60px);
}
.pokemon-gus-container .gus-flyout .gus-flyout-contents .gus-flyout {
  margin-right: auto;
}
a {
  text-decoration: none;
  color: #05afc3;
}
a:hover {
  text-decoration: underline;
}
img.icon {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
}
#root {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  overflow: hidden;
}
#App {
  background: #fff;
  background-size: auto;
  font-family: "Flexo-Regular";
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
#App #page-holder {
  width: 100%;
}
@font-face {
  font-family: "Flexo-Regular";
  src: url("../../fonts/Flexo-Regular.woff");
}
@font-face {
  font-family: "Flexo-Demi";
  src: url("../../fonts/Flexo-Demi.woff");
}
@font-face {
  font-family: "Flexo-Heavy";
  src: url("../../fonts/Flexo-Heavy.woff");
}
@font-face {
  font-family: "Flexo-Medium";
  src: url("../../fonts/Flexo-Medium.woff");
}
@media all and (max-width: 720px) {
  img.icon {
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
  }
}
@media all and (min-width: 720px) {
  img.icon {
    width: 48px;
    height: 48px;
    max-width: 48px;
    max-height: 48px;
  }
}
*:focus-visible {
  outline-color: #e23418;
}
*:focus:not(:focus-visible) {
  outline: none;
}
