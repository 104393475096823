.message-holder:hover {
  animation: 0.25s CARD_BOUNCE;
}
#league-detail-view {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  height: 100%;
  padding-top: 15px;
  font-size: 14px;
  flex-shrink: 0;
  flex-grow: 1;
}
#league-detail-view .child-event-cards .card-holder {
  padding: 2.5px 0px;
}
#league-detail-view .child-event-cards .card-holder .event-card {
  padding: 10px 15px;
}
#league-detail-view .child-event-cards .card-holder .event-card:hover {
  background: #55d9f2;
}
#league-detail-view .registration-times {
  width: 100%;
}
#league-detail-view .event_playtimes {
  display: flex;
  justify-content: center;
}
#league-detail-view .event_playtimes a {
  font-size: 18px;
  color: #33a9d6;
  display: flex;
  justify-content: center;
}
#league-detail-view .league-link-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#league-detail-view .event-calendar-button {
  font-size: 14px;
}
#league-detail-view .event-calendar-button a {
  color: #33a9d6;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  justify-content: center;
}
#league-detail-view > * {
  margin: 0 15px 15px;
}
#league-detail-view > *.legal-footer {
  margin: 0;
  padding: 10px;
}
#league-detail-view.empty {
  justify-content: center;
  align-items: center;
}
#league-detail-view .card-holder {
  display: block;
}
#league-detail-view .data-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}
#league-detail-view .data-row.split {
  justify-content: space-between;
}
#league-detail-view .data-row .robo-blocker {
  color: #05afc3;
  text-decoration: underline;
  cursor: pointer;
}
#league-detail-view .data-row .data-label {
  font-family: "Flexo-Heavy";
  white-space: nowrap;
  width: 125px;
}
#league-detail-view .section-header {
  font-family: "Flexo-Heavy";
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
#league-detail-view .section-header > *:not(:last-child) {
  margin-right: 0.5em;
}
#league-detail-view .section-header.emphasized {
  color: #aaa;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: bold;
  padding-bottom: 5px;
}
#league-detail-view .section-header .collapser {
  width: 1em;
  font-size: 1.5em;
  transform: rotate(90deg) translateX(4px);
  transition-duration: 0.25s;
}
#league-detail-view .league-description,
#league-detail-view .event-slider {
  flex-grow: 1;
}
#league-detail-view .detail-slider {
  flex-grow: 1;
}
#league-detail-view .button.register {
  background: #ed6935;
}
#league-detail-view .button.register.disabled {
  background: #aaaaaa;
  cursor: not-allowed;
}
#league-detail-view .button.register:not(.disabled):hover {
  background: #ef7a4c;
}
#league-detail-view .back-button {
  justify-content: flex-start;
  align-self: flex-start;
  color: #33a9d6;
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  align-items: baseline;
}
#league-detail-view .back-button .arrow {
  margin-right: 0.25em;
  text-align: center;
}
#league-detail-view .league-details {
  border: 1px solid #e0e0e0;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
}
#league-detail-view .entry-fees {
  display: flex;
  flex-direction: column;
}
#league-detail-view .third-party-url .data-row {
  flex-direction: column;
  border-radius: 5px;
}
#league-detail-view .third-party-url .data-row .data-label {
  margin-bottom: 5px;
}
#league-detail-view .third-party-url .data-row .exit-link {
  color: #fff;
  background: #4dad5b;
  border-radius: 5px;
  font-family: "Flexo-Heavy";
  font-size: 14px;
  align-self: stretch;
  text-align: center;
  padding: 10px 30px;
  cursor: pointer;
  width: 100%;
}
#league-detail-view .third-party-url .data-row .exit-link:hover {
  text-decoration: underline;
}
#league-detail-view .blurb {
  border-radius: 10px;
}
#league-detail-view .blurb .registration-time {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
}
#league-detail-view .blurb .registration-time .data-row {
  display: flex;
  flex: 1;
}
#league-detail-view .blurb .registration-time .data-label {
  display: flex;
  flex: 1;
}
#league-detail-view .blurb .registration-time .data-value {
  display: flex;
  color: #000;
  gap: 0.2rem;
}
#league-detail-view .entry-fees,
#league-detail-view .third-party-url {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#league-detail-view .entry-fees ul,
#league-detail-view .third-party-url ul {
  padding: 0;
  margin: 0;
}
#league-detail-view .entry-fees li.data-row,
#league-detail-view .third-party-url li.data-row {
  color: #666;
  display: flex;
  font-size: 14px;
  overflow-wrap: anywhere;
  gap: 0.5rem;
}
#league-detail-view .entry-fees li.data-row:not(:last-child),
#league-detail-view .third-party-url li.data-row:not(:last-child) {
  border-bottom: 2px solid #fff;
}
#league-detail-view .entry-fees li.data-row:last-child,
#league-detail-view .third-party-url li.data-row:last-child {
  margin-bottom: 0;
}
#league-detail-view .admission > *:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
#league-detail-view .league-details > *:not(:last-child) {
  margin-bottom: 15px;
}
#league-detail-view .league-details .categories > *:not(:last-child) {
  margin-bottom: 15px;
}
#league-detail-view .league-details .categories .category-header {
  font-weight: bold;
}
#league-detail-view .league-details .tags {
  background: #eee;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
#league-detail-view .league-details .tags .tag {
  width: 50%;
  margin-bottom: 5px;
}
#league-detail-view .league-details .tags .big-ol-warning {
  margin-bottom: 15px;
  font-size: 24px;
  width: 100%;
  font-weight: bold;
  font-family: Play;
  align-items: center;
  justify-content: center;
  background: #ad0202;
  color: #fff;
  padding: 3px 10px;
  border-radius: 15px;
  animation: 2s infinite WARNING;
  display: flex;
}
#league-detail-view .league-details.collapsed {
  max-height: 55px;
  overflow: hidden;
}
#league-detail-view .league-details.collapsed .collapser {
  transform: rotate(0) translateX(0) translateY(-2px);
}
#league-detail-view .league-body {
  display: flex;
  flex-direction: column;
}
#league-detail-view .league-body > *:not(:last-child) {
  margin-bottom: 15px;
}
#league-detail-view .league-body .blurb {
  font-size: 14px;
  margin-bottom: 15px;
  color: #000;
}
#league-detail-view .league-body .location-and-export {
  display: flex;
  flex-direction: column;
}
#league-detail-view .league-body .address-and-owner {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  background: #fff;
  border-radius: 10px;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  flex-wrap: wrap;
}
#league-detail-view .league-body .address-and-owner .owner,
#league-detail-view .league-body .address-and-owner .location {
  padding: 20px;
}
#league-detail-view .league-body .address-and-owner .spinner img {
  max-height: 70px;
}
#league-detail-view .league-body .address-and-owner > * {
  padding: 15px 0;
  white-space: normal;
  overflow: hidden;
}
#league-detail-view .league-body .address-and-owner > *:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}
#league-detail-view .age-group {
  font-weight: bold;
}
#league-detail-view .cost .blurb {
  font-size: 14px;
  margin-bottom: 15px;
  color: #aaaaaa;
}
@keyframes WARNING {
  50% {
    color: #fff;
  }
  75% {
    color: #0000;
  }
}
@media all and (min-width: 1000px) {
  #league-detail-view {
    border-right-width: 0;
  }
  #league-detail-view .league-header {
    font-size: 30px;
  }
  #league-detail-view .third-party-url .data-row {
    align-items: center;
    flex-direction: row;
  }
  #league-detail-view .third-party-url .data-row .data-label {
    margin: 0;
  }
  #league-detail-view #league-paused-message .headline {
    font-size: 20px;
  }
}
@media all and (max-width: 500px) {
  #league-detail-view .registration-time {
    font-size: 12px;
  }
  #league-detail-view .registration-time .data-label {
    font-family: "Flexo-Heavy";
  }
  #league-detail-view .section-header.emphasized {
    padding: 5px;
  }
  #league-detail-view .league-details {
    padding: 15px;
    font-size: 12px;
  }
  #league-detail-view .league-details .blurb {
    font-size: 12px;
    line-height: 16px;
  }
  #league-detail-view .league-details .tags {
    padding: 15px;
  }
  #league-detail-view .league-body .address-and-owner {
    font-size: 12px;
  }
  #league-detail-view .league-body .address-and-owner .owner {
    margin: 0px;
  }
  #league-detail-view .league-body .address-and-owner .location {
    margin: 0px;
  }
}
@keyframes CARD_BOUNCE {
  50% {
    padding-bottom: 5px;
    margin-top: -5px;
  }
}
