.pgo-outer-container {
  background-image: url("../../images/pgo/background.png");
  background-position: center;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.pgo-header {
  display: flex;
  justify-content: flex-end;
}
.pgo-header .login-wrapper {
  background-color: #333;
  padding: 10px 30px;
  border-bottom-left-radius: 15px;
}
.pgo-header .login-wrapper [class*="login_iconWrapper"] {
  font-size: 20px;
}
.pgo-header .login-wrapper [class*="login_loggedInDropdown"] {
  right: -30px;
}
.pgo-header .login-wrapper .spinner img {
  width: 32px;
  height: 32px;
}
.pgo-inner-container {
  display: flex;
  flex-direction: column;
  background-color: grey;
  border-radius: 25px;
  box-shadow: 0px 2px 5px #ccc;
  width: 75vw;
  margin: 0 auto 50px;
}
@media all and (max-width: 1000px) {
  .pgo-inner-container {
    width: 100%;
    border-radius: 0;
    margin: 0;
    height: 100%;
  }
}
.pgo-inner-container .more-info-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.pgo-inner-container .more-info-section .more-info-btn {
  color: #55d9f2;
  font-family: "Flexo-Heavy";
  font-size: 14px;
  cursor: pointer;
}
.pgo-inner-container .pgo-banner-mobile {
  background-image: url("../../images/pgo/banner.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.pgo-inner-container .pgo-banner-mobile .banner-image {
  max-height: 120px;
}
@media all and (min-width: 1000px) {
  .pgo-inner-container .pgo-banner-mobile {
    display: none;
  }
}
.pgo-inner-container .pgo-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-image: url("../../images/pgo/banner.png");
  background-position: bottom;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.pgo-inner-container .pgo-banner .banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pgo-inner-container .pgo-banner .banner-image {
  max-height: 150px;
}
@media all and (max-width: 1000px) {
  .pgo-inner-container .pgo-banner {
    display: none;
  }
}
.pgo-inner-container .pgo-content {
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
@media all and (max-width: 1000px) {
  .pgo-inner-container .pgo-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 30px 15px;
  }
}
.pgo-inner-container .pgo-content .label-section {
  text-align: center;
  margin-top: 20px;
}
.pgo-inner-container .pgo-content .label-section .label {
  font-family: "Flexo-demi";
  font-size: 30px;
}
.pgo-inner-container .pgo-content .label-section .sub-label {
  font-family: "Flexo-Regular";
  font-size: 15px;
}
.pgo-inner-container .pgo-content .pgo-button,
.pgo-inner-container .pgo-content #edb-login [class*="login_loginText"] {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-family: "Flexo-demi";
  width: 100%;
  background-color: #55d9f2;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
}
.pgo-inner-container .pgo-content .pgo-button:hover,
.pgo-inner-container .pgo-content #edb-login [class*="login_loginText"]:hover {
  background-color: #33a9d6;
  color: #fff;
}
.pgo-inner-container .pgo-content .pgo-button.disabled,
.pgo-inner-container
  .pgo-content
  #edb-login
  [class*="login_loginText"].disabled {
  background-color: #ededed;
  color: #aaaaaa;
  border: none;
}
.pgo-inner-container .pgo-content .pgo-button.disabled:hover,
.pgo-inner-container
  .pgo-content
  #edb-login
  [class*="login_loginText"].disabled:hover {
  cursor: not-allowed;
}
.pgo-inner-container .pgo-content .pgo-button.complete,
.pgo-inner-container
  .pgo-content
  #edb-login
  [class*="login_loginText"].complete,
.pgo-inner-container .pgo-content .pgo-button[class*="login_loggedInText"],
.pgo-inner-container
  .pgo-content
  #edb-login
  [class*="login_loginText"][class*="login_loggedInText"] {
  background-color: white;
  color: #55d9f2;
  border: 2px solid #55d9f2;
  cursor: default;
}
.pgo-inner-container .pgo-content .pgo-button.complete:hover,
.pgo-inner-container
  .pgo-content
  #edb-login
  [class*="login_loginText"].complete:hover,
.pgo-inner-container
  .pgo-content
  .pgo-button[class*="login_loggedInText"]:hover,
.pgo-inner-container
  .pgo-content
  #edb-login
  [class*="login_loginText"][class*="login_loggedInText"]:hover {
  cursor: not-allowed;
}
.pgo-inner-container .pgo-content .spinner img {
  width: 40px;
  height: 40px;
}
.pgo-inner-container .pgo-content .stage-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.pgo-inner-container .pgo-content .stage-container .stage-card {
  display: flex;
  justify-content: flex-start;
  position: relative;
  background-color: #fff;
  border: 2px solid #93d698;
  height: 300px;
  max-width: 240px;
  min-width: 240px;
  margin: 20px;
  border-radius: 20px;
  padding: 20px;
}
.pgo-inner-container
  .pgo-content
  .stage-container
  .stage-card:last-child
  .stage-image {
  max-height: 120px;
}
.pgo-inner-container
  .pgo-content
  .stage-container
  .stage-card
  .delink-container {
  text-align: center;
  margin-bottom: 10px;
  font-size: 11px;
}
.pgo-inner-container
  .pgo-content
  .stage-container
  .stage-card
  .delink-container
  .delink {
  cursor: pointer;
  text-decoration: underline;
  font-family: "Flexo-demi";
  color: #33a9d6;
}
.pgo-inner-container .pgo-content .stage-container .stage-card .order {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
  left: -20px;
  height: 40px;
  width: 40px;
  background-color: #93d698;
  border-radius: 100%;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}
.pgo-inner-container .pgo-content .stage-container .stage-card .content {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
}
.pgo-inner-container
  .pgo-content
  .stage-container
  .stage-card
  .stage-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.pgo-inner-container
  .pgo-content
  .stage-container
  .stage-card
  .stage-image-container
  .stage-image {
  max-width: 175px;
}
.pgo-inner-container
  .pgo-content
  .stage-container
  .stage-card
  .stage-description {
  font-family: "Flexo-Heavy";
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}
#pgo-legal-footer {
  display: flex;
  justify-content: center;
  background: #333;
}
#pgo-legal-footer .legal-footer {
  width: 60%;
}
@media all and (max-width: 1000px) {
  #pgo-legal-footer .legal-footer {
    width: 100%;
  }
}
@media all and (max-width: 1000px) {
  #pgo-legal-footer {
    justify-content: left;
    padding: 5px 10px;
  }
}
